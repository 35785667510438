/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import type { Service } from '@/pages/services';

// スクロールした高さがこの閾値を超えたら背景色を付ける
const BACKGROUND_COLOR_THRESHOLD_SCROLL_HEIGHT_PX = 200;

export const Header = ({ services }: { services: Service[] }) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const handleScroll = () => {
    setScrollHeight(window.scrollY);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    router.push(router.pathname, router.asPath, { locale: lang });
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [router.asPath]);

  return (
    <>
      <div className="HeaderSp flex items-center justify-between">
        <button
          type="button"
          aria-label="button"
          onClick={() => setMenuOpen(false)}
          className={`HeaderSp_overlay ${menuOpen && 'visible'}`}
        />
        <Link href="/">
          <img
            src="/public/images/Mask_Group_2.png"
            srcSet="/public/images/Mask_Group_2.png 1x, /public/images/Mask_Group_2@2x.png 2x"
            style={{
              width: '200px',
            }}
          />
        </Link>
        <button
          aria-label="button"
          className="fixed right-4 top-3 z-10 w-12"
          type="button"
          onClick={() => setMenuOpen(true)}
        >
          <img src="/public/images/menu_sp_open.png" style={{}} />
        </button>

        <div
          className={`SpMenu overflow-scroll ${
            menuOpen && 'open'
          } flex flex-col gap-4 p-8 pt-24 font-bold  text-white`}
        >
          <button
            aria-label="button"
            className="absolute right-6 top-6 w-12"
            type="button"
            onClick={() => setMenuOpen(false)}
          >
            <img src="/public/images/menu_sp_close.png" style={{}} />
          </button>
          <Link href="/about">
            <div
              className={`relative ${router.pathname === '/about' && 'active'}`}
            >
              <span>{t('事業所紹介')}</span>
              {router.pathname === '/about' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton
                  p={0}
                  style={{
                    backgroundColor: 'transparent',
                    justifyContent: 'justify-start',
                  }}
                >
                  <Box as="span" flex="1" textAlign="left" w="auto">
                    <span>{t('サービス紹介')}</span>
                    <AccordionIcon />
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {services.map((service) => (
                  <Link key={service.id} href={`/services?id=${service.id}`}>
                    <div
                      className={`relative py-2 ${
                        router.pathname === `/services?id=${service.id}` &&
                        'active'
                      }`}
                    >
                      <span className="text-sm">- {service.name}</span>
                      {router.asPath === `/services?id=${service.id}` && (
                        <div className="Header_menu-acitve" />
                      )}
                    </div>
                  </Link>
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Link href="/strengths">
            <div
              className={`relative ${
                router.pathname === '/strengths' && 'active'
              }`}
            >
              <span>{t('私たちの強み')}</span>
              {router.pathname === '/strengths' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <Link href="/hiring">
            <div
              className={`relative ${
                router.pathname === '/hiring' && 'active'
              }`}
            >
              <span>{t('採用情報')}</span>
              {router.pathname === '/hiring' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <div className="Header_lang" style={{ display: 'none' }}>
            <button
              type="button"
              className={`cursor-pointer ${
                i18n.language === 'ja' ? 'active' : ''
              }`}
              onClick={() => changeLanguage('ja')}
            >
              <div className="relative cursor-pointer">
                <span>JP</span>
                {i18n.language === 'ja' && (
                  <div className="Header_lang-acitve" />
                )}
              </div>
            </button>
            <div>
              <span>／</span>
            </div>
            <button
              type="button"
              className={`cursor-pointer ${
                i18n.language === 'en' ? 'active' : ''
              }`}
              onClick={() => changeLanguage('en')}
            >
              <div className="relative cursor-pointer">
                <span>EN</span>
                {i18n.language === 'en' && (
                  <div className="Header_lang-acitve" />
                )}
              </div>
            </button>
            <div>
              <span>／</span>
            </div>
            <button
              type="button"
              className={`cursor-pointer ${
                i18n.language === 'zh' ? 'active' : ''
              }`}
              onClick={() => changeLanguage('zh')}
            >
              <div className="relative">
                <span>CN</span>
                {i18n.language === 'zh' && (
                  <div className="Header_lang-acitve" />
                )}
              </div>
            </button>
          </div>
          <Link
            href="/contact"
            className="relative mt-4 bg-white p-4 text-center text-sm text-blue-600"
          >
            <img
              src="/public/images/email_icon_outline.png"
              className="absolute left-4 mt-1 w-4"
            />
            <span>お問合せする</span>
          </Link>
          
          <hr className="mt-4 h-[1px] bg-white/30" />
          <span>本社{'　'}東京事務所</span>
          <p className="text-xs font-thin">
          〒101-0047 東京都千代田区内神田３-4-11
            <br />
          サニー南神田ビル７階
          </p>
          <a href="tel:054-376-4245">
            <img src="/public/images/phone-number.png" className="w-40" />
          </a>
        </div>
      </div>
      <div
        className={`Header ${
          scrollHeight > BACKGROUND_COLOR_THRESHOLD_SCROLL_HEIGHT_PX
            ? 'bg-white shadow-[0_0_6px_0_rgba(0,0,0,0.24)]'
            : ''
        }`}
      >
        <Link href="/">
          <img
            src="/public/images/Mask_Group_2.png"
            srcSet="/public/images/Mask_Group_2.png 1x, /public/images/Mask_Group_2@2x.png 2x"
            style={{
              minWidth: '200px',
            }}
          />
        </Link>

        <div
          className="Header_menu"
          style={{
            fontSize: 16,
          }}
        >
          <Link href="/about">
            <div
              className={`relative ${router.pathname === '/about' && 'active'}`}
            >
              <span>{t('事業所紹介')}</span>
              {router.pathname === '/about' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <Link href="/services">
            <div
              className={`relative ${
                router.pathname === '/services' && 'active'
              }`}
            >
              <span>{t('サービス紹介')}</span>
              {router.pathname === '/services' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <Link href="/strengths">
            <div
              className={`relative ${
                router.pathname === '/strengths' && 'active'
              }`}
            >
              <span>{t('私たちの強み')}</span>
              {router.pathname === '/strengths' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <Link href="/hiring">
            <div
              className={`relative ${
                router.pathname === '/hiring' && 'active'
              }`}
            >
              <span>{t('採用情報')}</span>
              {router.pathname === '/hiring' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <Link href="/contact">
            <div
              className={`relative ${
                router.pathname === '/contact' && 'active'
              }`}
            >
              <span>{t('お問合せ')}</span>
              {router.pathname === '/contact' && (
                <div className="Header_menu-acitve" />
              )}
            </div>
          </Link>
          <div className="Header_lang cursor-pointer" style={{ display: 'none' }}>
            <button
              type="button"
              className={`cursor-pointer ${
                i18n.language === 'ja' ? 'active' : ''
              }`}
              onClick={() => changeLanguage('ja')}
            >
              <div className="relative">
                <span>JP</span>
                {i18n.language === 'ja' && (
                  <div className="Header_lang-acitve" />
                )}
              </div>
            </button>
            <div>
              <span>／</span>
            </div>
            <button
              type="button"
              className={`cursor-pointer ${
                i18n.language === 'en' ? 'active' : ''
              }`}
              onClick={() => changeLanguage('en')}
            >
              <div className="relative cursor-pointer">
                <span>EN</span>
                {i18n.language === 'en' && (
                  <div className="Header_lang-acitve" />
                )}
              </div>
            </button>
            <div>
              <span>／</span>
            </div>
            <button
              type="button"
              className={`cursor-pointer ${
                i18n.language === 'zh' ? 'active' : ''
              }`}
              onClick={() => changeLanguage('zh')}
            >
              <div className="relative">
                <span>CN</span>
                {i18n.language === 'zh' && (
                  <div className="Header_lang-acitve" />
                )}
              </div>
            </button>
          </div>

        </div>
      </div>
    </>
  );
};
