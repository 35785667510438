/* eslint-disable consistent-return */
import { type ReactNode, useEffect, useRef, useState } from 'react';

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import type { Service } from '@/pages/services';

type IMainProps = {
  meta: ReactNode;
  desktop: ReactNode;
  mobile: ReactNode;
  contentHeight?: number;
  contentHeightMobile?: number;
  autoHeight?: boolean;
  autoHeightMobile?: boolean;
  offsetTop?: number;
};

const Main = (props: IMainProps) => {
  const ref = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [baseHeight, setBaseHeight] = useState(0);
  const [scale, setScale] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const baseWidth = isMobile ? 768 : 1536;
  const [services, setServices] = useState<Service[]>([]);
  const [calclatedHeight, setCalclatedHeight] = useState(0);

  useEffect(() => {
    const fetchServices = async () => {
      const res = await fetch('/api/get_services');
      const data = await res.json();
      setServices(data.services);
    };
    fetchServices();
  }, []);

  useEffect(() => {
    if (props.autoHeight) {
      setCalclatedHeight(
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        document.getElementById('content')?.clientHeight * scale || 0
      );
    }
  }, [props.autoHeight, scale, contentHeight]);

  useEffect(() => {
    if (props.autoHeightMobile && isMobile) {
      setCalclatedHeight(
        // @ts-ignore
        // eslint-disable-next-line no-unsafe-optional-chaining
        document.getElementById('content')?.clientHeight * scale || 0
      );
    }
  }, [props.autoHeightMobile, scale, contentHeight]);

  useEffect(() => {
    if (!document.getElementById('content')) return;
    const resizeObserver = new ResizeObserver(() => {
      setContentHeight(document.getElementById('content')?.clientHeight || 0);
    });
    // @ts-ignore
    resizeObserver.observe(document.getElementById('content'));
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const updateScale = () => {
    if (!baseWidth || !baseHeight) return;
    const scaleWidth = window.innerWidth / baseWidth;
    const newScale = scaleWidth;
    setIsMobile(window.innerWidth < 768);
    setScale(newScale);
  };

  useEffect(() => {
    const newContentHeight = isMobile
      ? props.contentHeightMobile
      : props.contentHeight;

    setContentHeight(newContentHeight || 0);
  }, [props.contentHeight, props.contentHeightMobile, isMobile]);

  useEffect(() => {
    setBaseHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateScale);
    updateScale();
    setTimeout(updateScale, 100);
    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [baseWidth, baseHeight]);

  useEffect(() => {
    setContentHeight(document.getElementById('content')?.clientHeight || 0);
  }, [scale]);

  const style = {
    transform: `scale(${scale})`,
    transformOrigin: 'top left',
    height: (() => {
      // windowオブジェクトの存在確認とパス判定を安全に行う
      const isTopPage = typeof window !== 'undefined' && window.location.pathname === '/';
      
      if (isTopPage) {
        return (props.autoHeight && !isMobile) || (props.autoHeightMobile && isMobile)
          ? `${calclatedHeight}px`
          : `${contentHeight * scale}px`;
      }
      
      return (props.autoHeight && !isMobile) || (props.autoHeightMobile && isMobile)
        ? `${calclatedHeight}px` || 'auto'
        : `${(contentHeight - 1320) * scale}px`;
    })(),
  };

  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div id={isMobile ? 'bg-sp' : 'bg'} />
      <Header services={services} />
      <div ref={ref} style={style} className="w-full text-black antialiased">
        {props.meta}
        <main
          className="text-xl"
          id="content"
          style={{
            overflow: 'hidden',
            width: `${100 / scale}vw`,
          }}
        >
          {isMobile ? props.mobile : props.desktop}
        </main>
      </div>
      <Footer services={services} offsetTop={props.offsetTop} />
    </div>
  );
};

export { Main };
