export const CutoutLine = ({
  width,
  top,
  left = 'auto',
  right = 'auto',
  from = 'left',
  delay = 0,
}: {
  width: number;
  top: number;
  left?: number | string;
  right?: number | string;
  from?: 'left' | 'right';
  delay?: number;
}) => {
  return (
    <div
      className={`TopPage_cutout-line ${from}`}
      style={{
        width,
        top,
        left,
        right,
        transformOrigin: from === 'left' ? 'left' : 'right',
      }}
    >
      <span
        style={{
          animationDelay: `${delay}s`,
        }}
      />
    </div>
  );
};
